
import { httpHelper, DistributedDataService } from './http.service';
import { ENDPOINT } from './urls.service';

class ClaimService extends DistributedDataService {
  async list(params: URLSearchParams) {
    return httpHelper.get(`${ENDPOINT}/claim-secured/v1/claim`, params);
  }

  async get(id: string) {
    return httpHelper.get(`${ENDPOINT}/claim-secured/v1/claim/${id}`);
  }

  async create(payload: any) {
    return httpHelper.put(`${ENDPOINT}/claim-secured/v1/claim`, payload);
  }

  async update(id: string, payload: any) {
    return httpHelper.post(`${ENDPOINT}/claim-secured/v1/claim/${id}`, payload);
  }

  async report(params: URLSearchParams) {
    return httpHelper.get(`${ENDPOINT}/claim-secured/v1/report`, params);
  }

  async status() {
    return httpHelper.cacheWrapper('2d3593b2', () => httpHelper.get(`${ENDPOINT}/claim-secured/v1/status`));
  }

  async paymentType() {
    return httpHelper.cacheWrapper('fb6b651b', () => httpHelper.get(`${ENDPOINT}/claim-secured/v1/payment-type`));
  }

  async score() {
    return httpHelper.cacheWrapper('3b1449b0', () => httpHelper.get(`${ENDPOINT}/claim-secured/v1/score`));
  }
}

export const claimService = new ClaimService();
export default claimService;