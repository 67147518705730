import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Select } from 'antd';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import userService from '../../../services/user.service';
import authService from '../../../services/auth.service';
import { unionBy } from 'lodash';

function UserPickerLabel({ username, avatar }: { username: string, avatar?: string }) {
  return (<>
    <Avatar shape="square" size={22} src={avatar} style={{ marginTop: -4, marginRight: 6 }}>
      {String(username).split(' ').map((i: string) => i.charAt(0))}
    </Avatar>
    {username}
  </>);
}

function UserPicker(props: any) {
  const [loading, setLoading] = useState(false);
  const [value, setValue]: any = useState(null);
  const [options, setOptions]: any = useState([]);
  const sharedProps = omit(props, 'autoDefault');

  const onSearch = useCallback(async (text: string) => {
    setLoading(true);
    const data = await userService.search(text);
    setOptions(unionBy([...data, ...(value ? [value] : [])], 'usernameCanonical'));
    setLoading(false);
  }, [value]);

  const onChange = useCallback((usernameCanonical: string) => {
    const option = options.find((item: { usernameCanonical: string }) => item.usernameCanonical === usernameCanonical);
    if (props.onChange) {
      props.onChange(option || null);
    }
  }, [options, props]);

  const onInputKeyDown = useCallback(async (e: any) => {
    if (e.key === 'Enter' && options?.length) {
      e.preventDefault();
      onChange(options[0]?.usernameCanonical);
    }
  }, [options, onChange]);

  useEffect(() => {
    if (props.value?.username) {
      setValue(props.value);
      setOptions([props.value]);
    } else if (props.value) {
      setLoading(true);
      userService.search(props.value).then((data) => {
        if (data?.length) {
          setValue(data[0]);
          setOptions(data);
        } else {
          setValue({
            username: props.value,
            usernameCanonical: String(props.value).toLocaleLowerCase(),
          })
        }
      }).finally(() => setLoading(false));
    } else if (!props.value && props?.autoDefault) {
      authService.getUser().then(props?.onChange);
    } else {
      setValue(null);
      setOptions([]);
      setLoading(false);
    }
  }, [props?.value, props?.autoDefault, props?.onChange]);

  return (
    <Select
      {...sharedProps}
      showSearch
      defaultActiveFirstOption={false}
      filterOption={false}
      onSearch={debounce(onSearch, 200)}
      onChange={onChange}
      onInputKeyDown={onInputKeyDown}
      loading={loading}
      value={value?.usernameCanonical}
      options={options && options?.map((item: any) => ({
        value: String(item?.username).toLocaleLowerCase(),
        label: <UserPickerLabel key={item?._id} {...item} />
      }))}
    />
  );
}

export default UserPicker;