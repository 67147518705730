import { Badge, Button, Divider, List, Popover, Space, Tooltip, theme } from "antd";
import { CloseOutlined } from '@ant-design/icons';

import { useCallback, useEffect, useMemo, useState } from "react";
import notificationService from "../../services/notification.service";
import dateService from "../../services/date.service";
import { Link } from "react-router-dom";
import Spinner from "../common/Spinner";
import { notificationsSubject } from "../../services/shared.service";
import { FormattedMessage } from "react-intl";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from "@fortawesome/free-solid-svg-icons";

function NotificationPopover() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    items: [],
    count: 0,
    limit: 0,
    offset: 0,
  });

  const { token: { padding, screenSM } } = theme.useToken();

  const links = useMemo(() => ({
    'Animal': (id) => `/inventory/${id}`,
    'CR': (id) => `/inventory/${id}`,
    'Document': (id) => `/document/${id}`,
    'Coupon': (id) => `/coupon/${id}`,
    'Claim': (id) => `/claim/${id}`,
    'Fund': (id) => `/fund/${id}`,
  }), []);

  const onDelete = useCallback((id) => {
    setLoading(true);
    notificationService.delete([id]).then(setData).finally(() => setLoading(false));
  }, [])

  const onErase = useCallback((id) => {
    setLoading(true);
    notificationService.erase().then(() => setData({ count: 0, items: [], offset: 0, limit: 0 })).finally(() => setLoading(false));
  }, [])

  const onLoad = useCallback(() => {
    setLoading(true);
    notificationService.fetch().then(setData).finally(() => setLoading(false));
  }, [])

  useEffect(() => {
    const subscription = notificationsSubject.get().subscribe(() => setTimeout(onLoad, 1000));
    onLoad();
    return () => subscription.unsubscribe();
  }, [onLoad]);

  return (
    <Popover
      title="Центр уведомлений"
      trigger="click"
      content={
        <Spinner spinning={loading}>
          <Divider style={{ marginTop: padding, marginBottom: 0 }} />
          <List
            style={{ width: screenSM * 0.8, maxHeight: screenSM * 0.8, overflow: 'auto' }}
            dataSource={data?.items}
            renderItem={(item: any, index) => (
              <List.Item key={index}
                actions={[
                  <Button type="text" size="small" onClick={() => onDelete(item?._id)}>
                    <CloseOutlined />
                  </Button>
                ]}
              >
                <List.Item.Meta
                  title={[
                    item?.entity ?
                      <Link to={links[item?.type?._id] ? links[item?.type?._id](item?.entity) : '/'}>
                        {item?.message}
                      </Link> :
                      item?.message
                  ]}
                  description={[
                    item?.type.name,
                    ', ',
                    <Tooltip title={dateService(item?.date).format('DD.MM.YYYY HH:mm')}>
                      {dateService(item?.date).fromNow()}
                    </Tooltip>
                  ]}
                />
              </List.Item>
            )}
          />
          {data?.count > 3 ? [
            <Divider style={{ marginTop: 0, marginBottom: padding }} />,
            <Space>
              <Button danger onClick={onErase} size="small"><FormattedMessage id="deleteAll" />&nbsp;({data?.count})</Button>
            </Space>
          ] : null}
        </Spinner>
      }
      placement='topRight'
      arrow={{ pointAtCenter: true }}
    >
      <Button type="text" size="small">
        <Badge count={data?.count} size="small">
          <FontAwesomeIcon icon={faBell} size="lg" />
        </Badge>
      </Button>
    </Popover>
  );
}

export default NotificationPopover;