import { DistributedDataService, httpHelper } from './http.service';
import sessionService from './session.service';
import { ENDPOINT } from './urls.service';

class GroupService extends DistributedDataService {
  private searchKey = 'e1af7d58';

  async list(params: URLSearchParams) {
    return httpHelper.get(`${ENDPOINT}/group/v1/group`, params);
  }

  async get(id: string) {
    return httpHelper.get(`${ENDPOINT}/group/v1/group/${id}`);
  }

  async create(payload: any): Promise<any> {
    this.invalidate();
    return httpHelper.put(`${ENDPOINT}/group/v1/group`, payload);
  }

  async update(id: string, payload: any) {
    this.invalidate();
    return httpHelper.post(`${ENDPOINT}/group/v1/group/${id}`, payload);
  }

  async delete(id: string) {
    this.invalidate();
    return httpHelper.delete(`${ENDPOINT}/group/v1/group/${id}`);
  }

  async report(params: URLSearchParams): Promise<any> {
    return []
  }

  async search() {
    return httpHelper.cacheWrapper(this.searchKey, () => httpHelper.get(`${ENDPOINT}/group/v1/search`));
  }

  async roles() {
    return httpHelper.cacheWrapper(`ca4cc677`, () => httpHelper.get(`${ENDPOINT}/group/v1/role`));
  }

  invalidate() {
    sessionService.remove(this.searchKey, true);
  }
}

export const groupService = new GroupService();
export default groupService;