
import { httpHelper } from './http.service';
import { ENDPOINT } from './urls.service';

class JournalService {
  async list(params: URLSearchParams) {
    return httpHelper.get(`${ENDPOINT}/changelog-secured/v1/journal`, params);
  }

  async get(id: string) {
    return httpHelper.get(`${ENDPOINT}/changelog-secured/v1/revision/${id}`);
  }

  async count(params: URLSearchParams) {
    return httpHelper.get(`${ENDPOINT}/changelog-secured/v1/count`, params);
  }

  async type() {
    return httpHelper.cacheWrapper(`8c2006b0`, () => httpHelper.get(`${ENDPOINT}/changelog-secured/v1/type`));
  }

  async event() {
    return httpHelper.cacheWrapper(`04f15d57`, () => httpHelper.get(`${ENDPOINT}/changelog-secured/v1/event`));
  }

  async logs(id: string) {
    return httpHelper.get(`${ENDPOINT}/changelog-secured/v1/journal/${id}`);
  }
}

export const journalService = new JournalService();
export default journalService;