import { useCallback, useState } from 'react';
import { message } from 'antd';
import { useIntl } from 'react-intl';
import { useNavigate, useRevalidator } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Toast } from 'antd-mobile';

import logService from '../../../services/log.service';
import isString from 'lodash/isString';
import consentService from '../../../services/consent.service';
import { analyticService } from '../../../services/analytic.service';

export const useActionWrapper = (handler: Function, isDeleted = false, skipConsentCheck = false): [boolean, any] => {
  const intl = useIntl();
  const [loading, setLoading]: [boolean, any] = useState(false);
  const navigate = useNavigate();
  const revalidator = useRevalidator();

  return [
    loading,
    useCallback(async (data) => {

      if (!skipConsentCheck && !consentService.validate()) {
        if (isMobile) {
          Toast.show({ icon: 'fail', content: intl.formatMessage({ id: "consent.error" }) });
        } else {
          message.error(intl.formatMessage({ id: "consent.error" }));
        }
        return;
      }

      try {
        setLoading(true);
        const url = await handler(data);
        if (isMobile) {
          Toast.show({ icon: 'success', content: intl.formatMessage({ id: isDeleted ? 'deleted' : 'saved' }) });
        } else {
          message.success(intl.formatMessage({ id: isDeleted ? 'deleted' : 'saved' }));
        }

        if (url === false) {
          setTimeout(() => {
            revalidator.revalidate();
            setLoading(false);
          }, 1200);
        } else {
          setLoading(!isString(url));
        }

        if (isString(url)) {
          navigate(`${url}${window.location.search}`);
        }
      } catch (e) {

        if (isMobile) {
          Toast.show({ icon: 'fail', content: intl.formatMessage({ id: 'error' }) });
        } else {
          message.error(intl.formatMessage({ id: 'error' }));
        }

        analyticService.trackFormError();
        logService.error(e);
        setLoading(false);
      }
    }, [handler, intl, revalidator, navigate, isDeleted, skipConsentCheck])
  ];
};

export default useActionWrapper;