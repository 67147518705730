
import { httpHelper } from './http.service';
import { ENDPOINT } from './urls.service';

class NotificationService {
  async fetch() {
    return httpHelper.get(`${ENDPOINT}/notification-secured/v1/notifications`);
  }

  async delete(items: string[]) {
    return httpHelper.post(`${ENDPOINT}/notification-secured/v1/notifications`, { items });
  }

  async erase() {
    return httpHelper.delete(`${ENDPOINT}/notification-secured/v1/notifications`);
  }
}

export const notificationService = new NotificationService();
export default notificationService;