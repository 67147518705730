import { ReactNode, useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { Avatar, Badge, Button, Dropdown, Layout, Menu, notification, theme, Typography } from 'antd';
import { UserOutlined, MenuOutlined } from '@ant-design/icons';

import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { SafeArea, Tabs, Toast } from 'antd-mobile';

import manifest from '../../deploy-info';
import { MainMenuItem, useMenu } from './MenuHook';

import authService from '../../services/auth.service';
import logo from '../../assets/logo/logo.png';
import Loader from './Loader';
import { ImpersonalizationButton, ImpersonalizationStatusLine } from './Impersonalization';
import styles from './AppLayout.module.css';
import { UserRole } from '../../enums/UserRole';
import logService from '../../services/log.service';
import useConnectionStatus from '../common/ConnectionStatusHook';
import socketService from '../../services/socket.service';
import sessionService from '../../services/session.service';
import { changeRequestSubject, notificationsSubject } from '../../services/shared.service';
import NotificationPopover from './NotificationPopover';
import ConsentProvider from '../consent/ConsentProvider';
import FooterLinks from './FooterLinks';
import { analyticService } from '../../services/analytic.service';
import AnalyticProvider from './AnalyticProvider';
import AllowOnly from '../common/AllowOnly';

interface AppLayoutProps {
  children?: ReactNode;
}

function AppLayout({ children }: AppLayoutProps) {
  const [user, setUser]: [any, any] = useState(null);
  const [loading, setLoading] = useState(true);
  const intl = useIntl();
  const navigate = useNavigate();
  const { token: { colorBgContainer } } = theme.useToken();
  const [menu] = useMenu();
  const [connected] = useConnectionStatus();
  const [api, contextHolder] = notification.useNotification();

  socketService.getChangeRequests().subscribe(data => {
    changeRequestSubject.set(data);
  })

  socketService.getChanges().subscribe(data => {
    notificationsSubject.set(data);
  })

  useEffect(() => {
    if (user) {
      const subscription = socketService.getRolesChanged().subscribe(() => {
        if (isMobile) {
          Toast.show({
            content: intl.formatMessage({ id: "auth.roles.changed" }),
            position: 'bottom',
          })
        } else {
          api.open({
            placement: 'bottomRight',
            message: intl.formatMessage({ id: "auth.roles.changed" }),
            description: <Button type="primary" onClick={() => {
              sessionService.clear();
              navigate(0);
            }}><FormattedMessage id="reload" /></Button>
          });
        }
      });
      return () => subscription.unsubscribe();
    }
  }, [api, intl, navigate, user]);

  useEffect(() => {
    (async () => {
      try {
        const user = await authService.getUser();
        setUser(user);

        if (user && !user.roles.includes(UserRole.USER)) {
          navigate('/profile');
        }

        analyticService.trackUser(user?._id);

      } catch (e: any) {
        logService.error(e);
        navigate('/login');
      } finally {
        setLoading(false);
      }
    })()
  }, [navigate]);

  return (
    <AnalyticProvider>
      <Helmet>
        <title>{intl.formatMessage({ id: 'app' })}</title>
      </Helmet>
      {contextHolder}
      {loading ? <Loader /> : (
        <Layout className={classNames(styles.container, isMobile && styles.mobileContainer)}>
          <ImpersonalizationStatusLine />
          <ConsentProvider />

          <BrowserView>
            <header className={styles.headerContainer}>
              <Layout.Header className={styles.header}>
                <Link to="/" className={styles.logo}>
                  <img src={logo} alt={intl.formatMessage({ id: 'app' })} /><FormattedMessage id="app" />
                </Link>

                {menu.mainMenu?.length ?
                  <div className={styles.mainMenu}>
                    {menu.mainMenu.map((item: MainMenuItem) => (
                      <Link key={item.key} to={item.path} className={item.isActive ? styles.active : ''} title={item.label}>{item.icon} <span>{item.label}</span> <Badge count={item?.count} size="small" style={{ verticalAlign: 'text-top' }} /></Link>
                    ))}
                  </div>
                  : null}
                <div className={styles.rightMenu}>
                  <ImpersonalizationButton />
                  <NotificationPopover />

                  <Link to="/profile">
                    <Badge dot={!connected} size="small">
                      <Avatar src={user?.avatar} icon={<UserOutlined />} />
                    </Badge>
                  </Link>
                </div>
                <div className={styles.drawerMenu}>
                  <Dropdown menu={{ items: menu.drawerMenu }} placement="bottomLeft" arrow>
                    <Button type="link" icon={<MenuOutlined />} />
                  </Dropdown>
                </div>
              </Layout.Header>

              {menu.subMenu ? (
                <Layout.Content style={{ flex: 'none' }}>
                  <Menu mode="horizontal"
                    selectedKeys={menu.activeKeys}
                    className={styles.subMenu}
                    items={menu.subMenu.map((subItem: any) => ({ label: <>{subItem.label} <Badge count={subItem?.count} size="small" /></>, key: subItem.key, icon: subItem.icon }))} />
                </Layout.Content>
              ) : null}
            </header>
          </BrowserView>

          <MobileView>
            <Layout.Header className={classNames(styles.header, styles.headerMobile)}>
              <Link to="/" className={styles.logo}>
                <img src={logo} alt={intl.formatMessage({ id: 'app' })} />
              </Link>

              <nav className={styles.menuMobile}>
                {menu.mobileBarMenu.map((item: MainMenuItem): ReactNode => (
                  <Link key={item.key} to={item?.path} className={item?.isActive ? styles.menuMobileActive : undefined}>
                    <Badge count={item?.count || undefined} size="small">
                      {item.icon}
                    </Badge>
                  </Link>
                ))}
              </nav>
            </Layout.Header>

            {menu.subMenu ? (
              <Tabs
                activeKey={menu.activeKeys[menu.activeKeys.length - 1]}
                activeLineMode="full"
                style={{ '--title-font-size': '1.05em' }}
                className={styles.menuMobileSub}
              >
                {menu.subMenu.map((subItem: any) =>
                  <Tabs.Tab title={<>{subItem.label} <Badge count={subItem?.count} size="small" /></>} key={subItem.key} />
                )}
              </Tabs>
            ) : null}

            <SafeArea position='bottom' />
          </MobileView>

          <Layout.Content className={classNames(styles.content, isMobile && styles.mobileContent)} style={{ backgroundColor: colorBgContainer }}>
            <Outlet />
            {children}
          </Layout.Content>

          <Layout.Footer className={styles.footer}>
            <AllowOnly roles={[UserRole.USER]}>
              <FooterLinks />
            </AllowOnly>
            <p>
              <Typography.Text strong><FormattedMessage id="app" /></Typography.Text> &copy; {new Date().getFullYear()}. All rights reserved.
            </p>
            <Typography.Text type="secondary" style={{ fontSize: '0.9em' }}>
              {(manifest.commit).slice(0, 7)}-{manifest.build} ({manifest.buildTime.split('T')[0]})
            </Typography.Text>
          </Layout.Footer>
        </Layout >
      )
      }
    </AnalyticProvider>
  );
}

export default AppLayout;
