import { Observable, Subject } from "rxjs";

export class SharedService {
  private subject = new Subject();

  set(data) {
    this.subject.next(data);
  };

  get(): Observable<any> {
    return this.subject.asObservable();
  }
}

export const changeRequestSubject = new SharedService();
export const notificationsSubject = new SharedService();
