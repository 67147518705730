
import { DistributedDataService, httpHelper } from './http.service';
import { ENDPOINT } from './urls.service';

class PageService extends DistributedDataService {
  private indexKey = 'ea34b80a';

  async list(params: URLSearchParams) {
    return httpHelper.get(`${ENDPOINT}/page-secured/v1/page`, params);
  }

  async get(id: string) {
    return httpHelper.get(`${ENDPOINT}/page-secured/v1/page/${id}`);
  }

  async create(payload: any) {
    this.invalidate();
    return httpHelper.put(`${ENDPOINT}/page-secured/v1/page`, payload);
  }

  async update(id: string, payload: any) {
    this.invalidate();
    return httpHelper.post(`${ENDPOINT}/page-secured/v1/page/${id}`, payload);
  }

  async delete(id: string) {
    this.invalidate();
    return httpHelper.delete(`${ENDPOINT}/page-secured/v1/page/${id}`);
  }

  async report(params: URLSearchParams): Promise<any> {
    return [];
  }

  async slug(slug: string) {
    return httpHelper.get(`${ENDPOINT}/page-secured/v1/view/${slug}`);
  }

  async index(placement: string) {
    return httpHelper.cacheWrapper(`${this.indexKey}_${placement}`, () => httpHelper.get(`${ENDPOINT}/page-secured/v1/index`, new URLSearchParams([['placement', placement]])));
  }

  async placement(): Promise<any> {
    return httpHelper.cacheWrapper('9b9a98c7', () => httpHelper.get(`${ENDPOINT}/page-secured/v1/placement`));
  }

  private invalidate() {
    httpHelper.invalidateCache(`${this.indexKey}_INDEX`);
    httpHelper.invalidateCache(`${this.indexKey}_FOOTER`);
  }
}

export const pageService = new PageService();
export default pageService;