import { useCallback, useEffect, useState } from 'react';
import { Button, Form, Modal, Tooltip, message, theme } from 'antd';
import { useNavigate } from 'react-router-dom';
import authService from '../../services/auth.service';
import { FormattedMessage, useIntl } from 'react-intl';
import UserPicker from '../common/forms/UserPicker';
import AllowOnly from '../common/AllowOnly';
import { UserRole } from '../../enums/UserRole';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';

export function ImpersonalizationStatusLine() {
  const { token: { colorWarningActive } } = theme.useToken();
  const [user, setUser]: any = useState();

  useEffect(() => {
    authService.getUser().then(setUser);
  }, []);

  return user?.superuser ? <div style={{ backgroundColor: colorWarningActive, height: 3 }}></div> : undefined;
}

export function ImpersonalizationButton() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [user, setUser]: any = useState();

  const onClick = useCallback(() => {
    if (!user?.superuser) {
      setActive(true);
    } else {
      setLoading(true);
      authService.impersonalizateOff().then(() => {
        setTimeout(() => navigate(0), 1000);
      });
    }
  }, [user, navigate]);

  const onSubmit = useCallback(async (data: any) => {
    setLoading(true);
    setActive(false);
    try {
      await authService.impersonalizate(data?.user?.usernameCanonical);
      message.success(intl.formatMessage({ id: 'auth.welcome' }, { username: data?.user?.username }));
      navigate(0);
    } catch (e: any) {
      message.error(intl.formatMessage({ id: 'error' }));
      setLoading(false);
    }
  }, [intl, navigate]);

  useEffect(() => {
    authService.getUser().then(setUser);
  }, []);

  return (
    <AllowOnly defaultUser={user} roles={[UserRole.SUPERUSER, UserRole.IMPERSONALIZED_USER]}>

      <Tooltip title={intl.formatMessage({ id: user?.superuser ? "auth.impersonalization.off" : "impersonalization" })}>
        <Button type="text" size="small" onClick={onClick} loading={loading} danger={user?.superuser}>
          {!loading && <FontAwesomeIcon icon={faUserSecret} size="lg" />}
        </Button >
      </Tooltip>

      <Modal open={active} footer={false} onCancel={() => setActive(false)} title={intl.formatMessage({ id: "impersonalization" })}>
        <Form name="impersonalizate" onFinish={onSubmit} layout="vertical">
          <Form.Item label={intl.formatMessage({ id: 'user' })} name="user" rules={[{ required: true }]}>
            <UserPicker />
          </Form.Item>

          <Form.Item noStyle>
            <Button type="primary" htmlType="submit"><FormattedMessage id="auth.impersonalization.on" /></Button>
          </Form.Item>
        </Form>
      </Modal>

    </AllowOnly>
  );
}

export default ImpersonalizationButton;
