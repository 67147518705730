import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { analyticService } from '../../services/analytic.service';

function AnalyticProvider({ children }: { children?: ReactNode }) {
  const location = useLocation();

  useEffect(() => {
    analyticService.pageview();
  }, [location]);

  return children;
};

export default AnalyticProvider;
