// import { FormattedDate } from 'react-intl';
import dateService from '../../../services/date.service';

interface DateBeaconProps {
  value: Date | string;
  format?: string;
}

const DateBeacon = ({ value, format = "DD.MM.YYYY" }: DateBeaconProps) => {
  if (value) {
    // return <FormattedDate value={value} format={format} />
    return dateService(value).format(format);
  }

  return undefined;
};

export default DateBeacon;
