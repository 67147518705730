import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

function Loader() {
  return (<div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999
    }}
  >
    <Spin indicator={<LoadingOutlined spin />} />
  </div>);
}

export default Loader;
