import authService from './auth.service';
import { DistributedDataService, httpHelper } from './http.service';
import { ENDPOINT } from './urls.service';

class UserService extends DistributedDataService {
  async list(params: URLSearchParams) {
    return httpHelper.get(`${ENDPOINT}/user/v1/user`, params);
  }

  async get(id: string) {
    return httpHelper.get(`${ENDPOINT}/user/v1/user/${id}`);
  }

  async create(payload: any): Promise<any> {
    return Promise.reject('Not Allowed');
  }

  async update(id: string, payload: any) {
    authService.invalidate();
    return httpHelper.post(`${ENDPOINT}/user/v1/user/${id}`, payload);
  }

  async report(params: URLSearchParams): Promise<any> {
    return []
  }

  async search(query: string) {
    return httpHelper.cacheWrapper(`ecf8a781_${query}`, () => httpHelper.get(`${ENDPOINT}/user/v1/search`, new URLSearchParams([['query', query]])));
  }

  async namespace(): Promise<any> {
    return httpHelper.cacheWrapper('b4412b22', () => httpHelper.get(`${ENDPOINT}/user/v1/namespace`));
  }
}

export const userService = new UserService();
export default userService;