import React from 'react';
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import { useIntl } from 'react-intl';
import 'antd/dist/reset.css';

import { UserRole } from './enums/UserRole';
import AppLayout from './components/layout/AppLayout';
import AllowOnly from './components/common/AllowOnly';
import SuspensePage from './components/layout/SuspensePage';
import { Loader } from './loader';

const CaptchaProvider = React.lazy(() => import('./components/auth/CaptchaProvider'));
const ErrorPage = React.lazy(() => import('./components/layout/ErrorPage'));
const AuthLayout = React.lazy(() => import('./components/auth/AuthLayout'));
const LoginForm = React.lazy(() => import('./components/auth/LoginForm'));
const LogoutDialog = React.lazy(() => import('./components/auth/LogoutDialog'));
const OauthDialog = React.lazy(() => import('./components/auth/OauthDialog'));
const SignupForm = React.lazy(() => import('./components/auth/SignupForm'));
const ForgotPasswordForm = React.lazy(() => import('./components/auth/ForgotPasswordForm'));

const Home = React.lazy(() => import('./components/home/Home'));
const Profile = React.lazy(() => import('./components/user/Profile'));
const EnchantedJournal = React.lazy(() => import('./components/journal/EnchantedJournal'));
const EntityJournal = React.lazy(() => import('./components/journal/EntityJournal'));
const Inventory = React.lazy(() => import('./components/inventory/Inventory'));
const ChangeRequest = React.lazy(() => import('./components/inventory/ChangeRequest'));
const Animal = React.lazy(() => import('./components/inventory/Animal'));
const AnimalForm = React.lazy(() => import('./components/inventory/AnimalForm'));
const AnimalCreate = React.lazy(() => import('./components/inventory/AnimalCreate'));
const AnimalPhotos = React.lazy(() => import('./components/inventory/AnimalPhotos'));
const AnimalDocuments = React.lazy(() => import('./components/inventory/AnimalDocuments'));
const Coupons = React.lazy(() => import('./components/coupons/Coupons'));
const CouponsScore = React.lazy(() => import('./components/coupons/CouponsScore'));
const Coupon = React.lazy(() => import('./components/coupons/Coupon'));
const CouponForm = React.lazy(() => import('./components/coupons/CouponForm'));
const Funds = React.lazy(() => import('./components/funds/Funds'));
const Fund = React.lazy(() => import('./components/funds/Fund'));
const FundForm = React.lazy(() => import('./components/funds/FundForm'));
const Claims = React.lazy(() => import('./components/claims/Claims'));
const ClaimsScore = React.lazy(() => import('./components/claims/ClaimsScore'));
const Claim = React.lazy(() => import('./components/claims/Claim'));
const ClaimForm = React.lazy(() => import('./components/claims/ClaimForm'));
const Documents = React.lazy(() => import('./components/documents/Documents'));
const Document = React.lazy(() => import('./components/documents/Document'));
const DocumentForm = React.lazy(() => import('./components/documents/DocumentForm'));
const DocumentFactory = React.lazy(() => import('./components/documents/DocumentFactory'));
const Users = React.lazy(() => import('./components/user/Users'));
const User = React.lazy(() => import('./components/user/User'));
const UserForm = React.lazy(() => import('./components/user/UserForm'));
const Groups = React.lazy(() => import('./components/user/Groups'));
const Group = React.lazy(() => import('./components/user/Group'));
const GroupForm = React.lazy(() => import('./components/user/GroupForm'));
const Consents = React.lazy(() => import('./components/consent/Consents'));
const Consent = React.lazy(() => import('./components/consent/Consent'));
const ConsentForm = React.lazy(() => import('./components/consent/ConsentForm'));
const ConsentData = React.lazy(() => import('./components/consent/ConsentData'));
const Pages = React.lazy(() => import('./components/pages/Pages'));
const Page = React.lazy(() => import('./components/pages/Page'));
const PageForm = React.lazy(() => import('./components/pages/PageForm'));
const Report = React.lazy(() => import('./components/reports/Report'));
const Info = React.lazy(() => import('./components/pages/Info'));
const InfoIndex = React.lazy(() => import('./components/pages/InfoIndex'));

export default function App() {
  const intl = useIntl();
  const routes = createBrowserRouter([
    {
      path: '/login',
      element: (
        <SuspensePage title={intl.formatMessage({ id: 'auth.login' })}>
          <CaptchaProvider>
            <AuthLayout>
              <LoginForm />
            </AuthLayout>
          </CaptchaProvider>
        </SuspensePage>
      )
    },
    {
      path: '/logout',
      element: (
        <SuspensePage title={intl.formatMessage({ id: 'auth.logout' })}>
          <LogoutDialog />
        </SuspensePage>
      )
    },
    {
      path: '/oauth',
      element: (
        <SuspensePage title={intl.formatMessage({ id: 'auth.login' })}>
          <CaptchaProvider>
            <OauthDialog />
          </CaptchaProvider>
        </SuspensePage>
      )
    },
    {
      path: '/signup',
      element: (
        <SuspensePage title={intl.formatMessage({ id: 'auth.createAnAccount' })}>
          <CaptchaProvider>
            <AuthLayout anew={true} title={intl.formatMessage({ id: 'auth.createAnAccount' })}>
              <SignupForm />
            </AuthLayout>
          </CaptchaProvider>
        </SuspensePage>
      )
    },
    {
      path: '/forgot-password',
      element: (
        <SuspensePage title={intl.formatMessage({ id: 'auth.restoringPassword' })}>
          <CaptchaProvider>
            <AuthLayout anew={true} title={intl.formatMessage({ id: 'auth.restoringPassword' })}>
              <ForgotPasswordForm />
            </AuthLayout>
          </CaptchaProvider>
        </SuspensePage>
      )
    },
    {
      path: "/",
      errorElement: <AppLayout><ErrorPage /></AppLayout>,
      element: <AppLayout />,
      children: [
        { index: true, element: <SuspensePage title={intl.formatMessage({ id: 'menu.home' })}><Home /></SuspensePage> },
        { path: '/profile', element: <SuspensePage title={intl.formatMessage({ id: 'menu.profile' })}><Profile /></SuspensePage> },
        {
          path: "/journal",
          element: <SuspensePage title={intl.formatMessage({ id: 'menu.journal' })}>
            <AllowOnly roles={[UserRole.JOURNAL_ENCHANTED]} showError={true}><EnchantedJournal /></AllowOnly>
          </SuspensePage>
        },
        {
          path: "/inventory",
          element: (
            <AllowOnly roles={[UserRole.INVENTORY]} showError={true}>
              <SuspensePage title={intl.formatMessage({ id: 'menu.inventory' })}>
                <Outlet />
              </SuspensePage>
            </AllowOnly>
          ),
          children: [
            { index: true, element: <Inventory /> },
            {
              path: "change-request",
              element: <AllowOnly roles={[UserRole.INVENTORY_SUPERVISOR]} showError={true}><ChangeRequest /></AllowOnly>
            },
            {
              path: ":_id/copy",
              element: <AllowOnly roles={[UserRole.INVENTORY_CREATE]} showError={true}><AnimalCreate /></AllowOnly>,
              loader: Loader.petNew
            },
            {
              path: "new",
              element: <AllowOnly roles={[UserRole.INVENTORY_CREATE]} showError={true}><AnimalCreate /></AllowOnly>,
              loader: Loader.petNew
            },
            {
              path: ":_id",
              loader: Loader.pet,
              element: <Animal />,
              children: [
                { index: true, loader: Loader.pet, Component: AnimalForm },
                { path: "photos", loader: Loader.pet, Component: AnimalPhotos },
                { path: "journal", loader: Loader.logs, element: <EntityJournal backPath='/inventory' /> },
                { path: "document", loader: Loader.document, Component: AnimalDocuments },
              ],
            },
          ]
        },
        {
          path: "/coupon",
          element: (
            <AllowOnly roles={[UserRole.COUPON_SUPERVISOR, UserRole.COUPONS]} showError={true}>
              <SuspensePage title={intl.formatMessage({ id: 'menu.coupons' })}>
                <Outlet />
              </SuspensePage>
            </AllowOnly>
          ),
          children: [
            { index: true, element: <Coupons /> },
            { path: 'score', element: <CouponsScore /> },
            {
              path: "new",
              element: <AllowOnly roles={[UserRole.COUPON_CREATE]} showError={true}><Coupon /></AllowOnly>,
              children: [{ index: true, loader: Loader.couponNew, Component: CouponForm }],
            },
            {
              path: ":_id",
              loader: Loader.coupon,
              element: <Coupon />,
              children: [
                { index: true, loader: Loader.coupon, Component: CouponForm },
                { path: 'journal', loader: Loader.logs, element: <EntityJournal backPath='/coupon' chartProps={['refund']} /> },
              ]
            },
          ]
        },
        {
          path: '/fund',
          element: (
            <AllowOnly roles={[UserRole.COUPON_SUPERVISOR, UserRole.COUPON_FUND]} showError={true}>
              <SuspensePage title={intl.formatMessage({ id: 'menu.funds' })}>
                <Outlet />
              </SuspensePage>
            </AllowOnly>
          ),
          children: [
            { index: true, Component: Funds },
            {
              path: "new",
              element: <AllowOnly roles={[UserRole.COUPON_FUND_CREATE]} showError={true}><Fund /></AllowOnly>,
              children: [{ index: true, Component: FundForm }]
            },
            {
              path: ":_id", loader: Loader.fund, Component: Fund,
              children: [
                { index: true, loader: Loader.fund, Component: FundForm },
                { path: 'journal', loader: Loader.logs, element: <EntityJournal backPath='/fund' chartProps={['target', 'collected']} /> },
              ]
            },
          ]
        },
        {
          path: '/claim',
          element: (
            <AllowOnly roles={[UserRole.CLAIM]} showError={true}>
              <SuspensePage title={intl.formatMessage({ id: 'menu.claim' })}>
                <Outlet />
              </SuspensePage>
            </AllowOnly>
          ),
          children: [
            { index: true, element: <Claims /> },
            { path: 'score', element: <ClaimsScore /> },
            {
              path: "new",
              element: <AllowOnly roles={[UserRole.CLAIM_CREATE]} showError={true}><Claim /></AllowOnly>,
              children: [{ index: true, Component: ClaimForm }]
            },
            {
              path: ":_id", loader: Loader.claim, Component: Claim,
              children: [
                { index: true, loader: Loader.claim, Component: ClaimForm },
                { path: 'journal', loader: Loader.logs, element: <EntityJournal backPath='/claim' /> },
              ]
            },
          ]
        },
        {
          path: '/document',
          element: (
            <AllowOnly roles={[UserRole.DOCUMENT]} showError={true}>
              <SuspensePage title={intl.formatMessage({ id: 'menu.document' })}>
                <Outlet />
              </SuspensePage>
            </AllowOnly>
          ),
          children: [
            { index: true, element: <Documents /> },
            {
              path: "new",
              element: <AllowOnly roles={[UserRole.DOCUMENT_CREATE]} showError={true}><Document mode="create" /></AllowOnly>,
              children: [{ index: true, Component: DocumentForm }]
            },
            {
              path: ":_id", loader: Loader.document, Component: Document,
              children: [
                { index: true, loader: Loader.document, Component: DocumentFactory },
                { path: 'journal', loader: Loader.logs, element: <EntityJournal backPath='/document' /> },
              ]
            },
          ]
        },
        {
          path: '/report',
          element: (
            <SuspensePage title={intl.formatMessage({ id: 'menu.report' })}>
              <Report />
            </SuspensePage>
          ),
        },
        {
          path: '/user',
          element: (
            <AllowOnly roles={[UserRole.SUPERUSER]} showError={true}>
              <SuspensePage title={intl.formatMessage({ id: 'menu.users' })}>
                <Outlet />
              </SuspensePage>
            </AllowOnly>
          ),
          children: [
            { index: true, Component: Users },
            {
              path: ":_id", loader: Loader.user, Component: User,
              children: [
                { index: true, loader: Loader.user, Component: UserForm },
                { path: 'journal', loader: Loader.logs, element: <EntityJournal backPath='/user' /> },
              ]
            },
          ]
        },
        {
          path: '/group',
          element: (
            <AllowOnly roles={[UserRole.SUPERUSER]} showError={true}>
              <SuspensePage title={intl.formatMessage({ id: 'menu.groups' })}>
                <Outlet />
              </SuspensePage>
            </AllowOnly>
          ),
          children: [
            { index: true, Component: Groups },
            {
              path: "new",
              Component: Group,
              children: [{ index: true, Component: GroupForm }]
            },
            {
              path: ":_id", loader: Loader.group, Component: Group,
              children: [
                { index: true, loader: Loader.group, Component: GroupForm },
                { path: 'journal', loader: Loader.logs, element: <EntityJournal backPath='/group' /> },
              ]
            },
          ]
        },
        {
          path: '/consent',
          element: (
            <AllowOnly roles={[UserRole.CONSENT_SUPERVISOR]} showError={true}>
              <SuspensePage title={intl.formatMessage({ id: 'menu.consents' })}>
                <Outlet />
              </SuspensePage>
            </AllowOnly>
          ),
          children: [
            { index: true, Component: Consents },
            {
              path: "new",
              Component: Consent,
              children: [{ index: true, Component: ConsentForm }]
            },
            {
              path: ":_id",
              loader: Loader.consent,
              Component: Consent,
              children: [{ index: true, loader: Loader.consent, Component: ConsentData }]
            },
          ]
        },
        {
          path: '/page',
          element: (
            <AllowOnly roles={[UserRole.SUPERUSER]} showError={true}>
              <SuspensePage title={intl.formatMessage({ id: 'menu.pages' })}>
                <Outlet />
              </SuspensePage>
            </AllowOnly>
          ),
          children: [
            { index: true, Component: Pages },
            {
              path: "new",
              Component: Page,
              children: [{ index: true, Component: PageForm }]
            },
            {
              path: ":_id", loader: Loader.page, Component: Page,
              children: [
                { index: true, loader: Loader.page, Component: PageForm },
                { path: 'journal', loader: Loader.logs, element: <EntityJournal backPath='/page' /> },
              ]
            },
          ]
        },
        {
          path: 'info',
          element: <SuspensePage title=''><Outlet /></SuspensePage>,
          children: [
            { index: true, Component: InfoIndex },
            { path: ":slug", loader: Loader.info, Component: Info },
          ]
        },
      ],
    },
    {
      path: '*',
      element: <AppLayout><ErrorPage /></AppLayout>
    },
  ]);

  return <RouterProvider router={routes} />;
};