import React from 'react';
import ReactDOM from 'react-dom/client';
import 'antd/dist/reset.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import AppProvider from './components/layout/Provider';
import { analyticService } from './services/analytic.service';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

analyticService.initialize();

root.render(
  <AppProvider>
    <App />
  </AppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
