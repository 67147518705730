
class SessionService {
  private _sessionStorage = window.sessionStorage;
  private _localStorage = window.localStorage;

  set(key: string, value: any, crossSession = false) {
    const data = JSON.stringify(value);
    crossSession ?
      this._localStorage.setItem(key, data) :
      this._sessionStorage.setItem(key, data);
  }

  get(key: string, crossSession = false) {
    const data = crossSession ?
      this._localStorage.getItem(key) :
      this._sessionStorage.getItem(key);

    try {
      return data && JSON.parse(data);
    } catch (e) {
      return null;
    }
  }

  remove(key: string, crossSession = false) {
    this._sessionStorage.removeItem(key);
    crossSession && this._localStorage.removeItem(key);
  }

  clear() {
    this._sessionStorage.clear();
    this._localStorage.clear();
  }
}

export const sessionService = new SessionService();
export default sessionService;