import { DistributedDataService, httpHelper } from './http.service';
import sessionService from './session.service';
import { ENDPOINT } from './urls.service';

class ConsentService extends DistributedDataService {
  private validationKey = '6929f832';

  async list(params: URLSearchParams) {
    return httpHelper.get(`${ENDPOINT}/notification-secured/v1/consent`, params);
  }

  async get(id: string) {
    return httpHelper.get(`${ENDPOINT}/notification-secured/v1/consent/${id}`);
  }

  async create(payload: any): Promise<any> {
    return httpHelper.put(`${ENDPOINT}/notification-secured/v1/consent`, payload);
  }

  async update(id: string, payload: any) {
    throw new Error('Not Allowed');
  }

  async report(params: URLSearchParams): Promise<any> {
    return [];
  }

  async assignments(): Promise<any> {
    const data = await httpHelper.get(`${ENDPOINT}/notification-secured/v1/assignments`);
    consentService.invalidate(data?.length);
    return data;
  }

  async signed(): Promise<any> {
    return httpHelper.get(`${ENDPOINT}/notification-secured/v1/signed`);
  }

  async sign(id: string): Promise<any> {
    return httpHelper.post(`${ENDPOINT}/notification-secured/v1/assignments/${id}`, {});
  }

  validate() {
    return !sessionService.get(this.validationKey);
  }

  invalidate(count: number) {
    if (count > 0) {
      sessionService.set(this.validationKey, count);
    } else {
      sessionService.remove(this.validationKey);
    }
  }
}

export const consentService = new ConsentService();
export default consentService;