
import { useEffect, useState } from "react";
import pageService from "../../services/page.service";
import { Divider, Typography } from "antd";
import { Link } from "react-router-dom";

import styles from './FooterLinks.module.css';
import authService from "../../services/auth.service";
import logo from '../../assets/logo/logo.png';
import { useIntl } from "react-intl";
import dateService from "../../services/date.service";
import DateBeacon from "../common/beacons/DateBeacon";

function FooterLink({ item }) {
  return <Link to={`/info/${item?.slug}`} target="_blank" title={item?.name}>
    {item?.name}
    {item?.date && !dateService().diff(dateService(item?.date), 'month') ?
      <Typography.Text type="secondary" style={{ fontSize: '0.85em' }}> (<DateBeacon value={item?.date} />)</Typography.Text>
      : null}
  </Link>
}

function FooterLinks() {
  const intl = useIntl();
  const [user, setUser]: any = useState(null);
  const [items1, setItems1]: any = useState([]);
  const [items2, setItems2]: any = useState([]);

  useEffect(() => {
    authService.getUser().then(setUser);
    pageService.index('FOOTER').then((data: any) => {
      if (data?.count > 3) {
        const pivot = Math.ceil(data?.count / 2);
        setItems1(data?.items.slice(0, pivot));
        setItems2(data?.items.slice(pivot));
      } else {
        setItems1(data?.items);
      }
    });
  }, []);

  return items1.length || items2.length ? (
    <>
      <Typography.Title level={4}><img src={logo} alt={intl.formatMessage({ id: 'app' })} style={{ height: 20, verticalAlign: "text-top", filter: 'grayscale(1)' }} /> {user?.username}</Typography.Title>

      <nav className={styles.footerLinks}>
        <div className={styles.footerLinksGroup}>
          {items1?.map((item: any) => <FooterLink key={item?._id} item={item} />)}
        </div>
        <div className={styles.footerLinksGroup}>
          {items2?.map((item: any) => <FooterLink key={item?._id} item={item} />)}
        </div>
      </nav>

      <Divider />
    </>
  ) : undefined;
}

export default FooterLinks;