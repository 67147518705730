
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import Agent from "agentkeepalive";
import authService from './auth.service';
import sessionService from './session.service';

const pool: { [key: string]: Promise<any> } = {};
export interface RequestCacheData {
  data: any;
  ttl: number;
}

export abstract class DistributedDataService {
  abstract list(params: URLSearchParams): Promise<any>;
  abstract get(id: string): Promise<any>;
  abstract create(payload: any): Promise<any>;
  abstract update(id: string, payload: any): Promise<any>;
  abstract report(params: URLSearchParams): Promise<any>;
}

class HttpHelper {


  constructor(private readonly axiosInstance: AxiosInstance) { }

  async get(url: string, params?: URLSearchParams) {
    const token = await authService.getToken();
    const _url = `${url}?${(params || empySearch).toString()}`;
    const { data } = await this.getPoolWrapper(_url, {
      withCredentials: true,
      headers: { Authorization: token },
    });
    return data;
  }

  async post(url: string, payload: any) {
    const token = await authService.getToken();
    const { data } = await this.axiosInstance.post(url, payload, {
      withCredentials: true,
      headers: { Authorization: token },
    });
    return data;
  }

  async put(url: string, payload: any) {
    const token = await authService.getToken();
    const { data } = await this.axiosInstance.put(url, payload, {
      withCredentials: true,
      headers: { Authorization: token },
    });
    return data;
  }

  async delete(url: string) {
    const token = await authService.getToken();
    const { data } = await this.axiosInstance.delete(url, {
      withCredentials: true,
      headers: { Authorization: token },
    });
    return data;
  }

  getPoolWrapper(url: string, options: AxiosRequestConfig): Promise<any> {
    if (!!pool[url]) {
      return pool[url];
    }

    pool[url] = this.axiosInstance.get(url, options).finally(() => {
      setTimeout(() => {
        delete pool[url];
      }, 1000);
    })

    return pool[url];
  }

  async cacheWrapper(key: string, handler: Function, ttl = 600000, invalidate = false, crossSession = true) {
    const _cached: RequestCacheData = sessionService.get(key.toLocaleLowerCase(), true);

    if (!_cached?.data || invalidate || (new Date().getTime()) >= Number(_cached?.ttl ?? 0)) {
      try {
        const data = await handler();
        sessionService.set(key.toLocaleLowerCase(), { data, ttl: (new Date().getTime()) + ttl } as RequestCacheData, crossSession);
        return data;
      } catch (e) {
        this.invalidateCache(key);
        throw e;
      }
    }

    return _cached.data;
  }

  async invalidateCache(key: string) {
    sessionService.remove(key.toLocaleLowerCase(), true);
  }
}

const agent = new Agent();

export const httpService = axios.create({ httpAgent: agent, httpsAgent: agent });
export const httpHelper = new HttpHelper(httpService);
export const empySearch = new URLSearchParams();
export default httpService;