import { useEffect, useState } from 'react';
import socketService from '../../services/socket.service';

export const useConnectionStatus = (): [boolean] => {
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    const subscription = socketService.getConnectionStatus().subscribe(setConnected);
    socketService.connect();
    return () => subscription.unsubscribe();
  }, []);

  return [connected];
};

export default useConnectionStatus;