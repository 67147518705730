import { useCallback, useEffect, useState } from "react";
import consentService from "../../services/consent.service";
import { Button, Modal, theme } from "antd";
import useActionWrapper from "../common/forms/ActionWrapperHook";
import { FormattedMessage } from "react-intl";
import socketService from "../../services/socket.service";
import { analyticService } from "../../services/analytic.service";
import RichText from "../common/RichText";

function ConsentProvider() {
  const { token: { marginXL } } = theme.useToken();
  const [data, setData] = useState([]);

  const onSuccess = useCallback((id) => {
    const newData = data?.filter((item: any) => item?._id !== id);
    setData(newData);
  }, [data]);

  const [processing, onSign] = useActionWrapper(async (id) => {
    consentService.sign(id).then(() => onSuccess(id));
    analyticService.trackSignConsent();
    return false;
  }, false, true);

  useEffect(() => {
    consentService.assignments().then(setData);

    const subscription = socketService.getConsentChanged().subscribe(() => {
      consentService.assignments().then(setData);
    });

    return () => subscription.unsubscribe();
  }, []);

  return data?.length ? data?.map((item: any) => {
    analyticService.modalview(item?.name);
    return <Modal title={item?.name} open={true} closable={false} footer={false} width={900}>
      <RichText text={item?.text} style={{ marginBottom: marginXL }} />
      <Button type="primary" loading={processing} onClick={() => onSign(item?._id)}>
        <FormattedMessage id="consent.sign" />
      </Button>
    </Modal>
  }) : undefined;
}

export default ConsentProvider;
