import React, { ReactNode, useEffect, useState } from 'react';
import { Result } from 'antd';
import { UserRole } from '../../enums/UserRole';
import authService, { User } from '../../services/auth.service';
import Spinner from './Spinner';

interface RestrictedAreaProps {
  children?: ReactNode;
  roles?: UserRole[];
  defaultUser?: Partial<User>;
  showLoader?: boolean
  showError?: boolean
};

function AllowOnly({ defaultUser, roles, children, showLoader, showError }: RestrictedAreaProps) {
  const [user, setUser]: [any, any] = useState(defaultUser);
  useEffect(() => {
    (async () => {
      try {
        setUser(await authService.getUser());
      } catch (e) {
        setUser({ roles: [] });
      }
    })();
  }, [defaultUser]);

  if (!roles) {
    return (<>{children}</>);
  }

  if (!user) {
    return showLoader ? <Spinner /> : null;
  }

  return authService.hasRoles(user, roles) ? (
    <>{children}</>
  ) : showError ? (
    <Result status="403" title="403" subTitle="Sorry, you are not authorized to access this page." />
  ) : null;
}

export default AllowOnly;
