import React, { ReactNode, useEffect } from 'react';
import { useIntl } from 'react-intl';
import Loader from './Loader';

function SuspensePage({ children, title }: { children?: ReactNode, title: string }) {
  const intl = useIntl();

  useEffect(() => {
    document.title = `${title ? `${title} - ` : ''}${intl.formatMessage({ id: 'app' })}`;
  }, [intl, title]);

  return (
    <React.Suspense fallback={<Loader />}>
      {children}
    </React.Suspense>
  );
}

export default SuspensePage;
