import { LoaderFunctionArgs } from "react-router-dom";
import petService from "./services/pet.service";
import couponService from "./services/coupon.service";
import fundService from "./services/fund.service";
import userService from "./services/user.service";
import claimService from "./services/claim.service";
import documentService from "./services/document.service";
import authService from "./services/auth.service";
import groupService from "./services/group.service";
import consentService from "./services/consent.service";
import pageService from "./services/page.service";
import journalService from "./services/journal.service";

export class Loader {
  static async pet({ params }: LoaderFunctionArgs) {
    return petService.get(params._id as string);
  }

  static async petNew({ params }: LoaderFunctionArgs) {
    const types = await petService.getTypes();
    const data = params._id ? await petService.get(params._id) : null;

    if (data?._id) {
      delete data._id;
    }

    return { types, data };
  }

  static async coupon({ params }: LoaderFunctionArgs) {
    return couponService.get(params._id as string);
  }

  static async couponNew({ params }: LoaderFunctionArgs) {
    const user = await authService.getUser();
    return { visitor: user?.fullname };
  }

  static async fund({ params }: LoaderFunctionArgs) {
    return fundService.get(params._id as string);
  }

  static async claim({ params }: LoaderFunctionArgs) {
    return claimService.get(params._id as string);
  }

  static async user({ params }: LoaderFunctionArgs) {
    return userService.get(params._id as string);
  }

  static async group({ params }: LoaderFunctionArgs) {
    return groupService.get(params._id as string);
  }

  static async consent({ params }: LoaderFunctionArgs) {
    return consentService.get(params._id as string);
  }

  static async page({ params }: LoaderFunctionArgs) {
    return pageService.get(params._id as string);
  }

  static async info({ params }: LoaderFunctionArgs) {
    return pageService.slug(params?.slug as string);
  }

  static async document({ params }: LoaderFunctionArgs) {
    return documentService.get(params._id as string);
  }

  static async logs({ params }: LoaderFunctionArgs) {
    return journalService.logs(params._id as string);
  }
}

export default Loader;