import { ReactNode, useEffect, useState } from 'react';
import { type MenuProps } from 'antd';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faGear, faHouse, faPaw, faReceipt, faTicket, faUser } from '@fortawesome/free-solid-svg-icons';

import { UserRole } from "../../enums/UserRole";
import authService from '../../services/auth.service';
import petService from '../../services/pet.service';
import socketService from '../../services/socket.service';

export interface MenuData {
  mainMenu: MainMenuItem[];
  subMenu: SubMenuItem[];
  userMenu: MenuProps['items'],
  drawerMenu: MenuProps['items'],
  mobileBarMenu: MainMenuItem[],
  activeKeys: string[];
}

export interface MainMenuItem {
  label: string;
  key: string;
  path: string;
  icon?: ReactNode;
  isActive: boolean;
  items?: SubMenuItem[];
  count?: number;
}

export interface SubMenuItem {
  label: any;
  key: string;
  isActive?: boolean;
  icon?: any;
  count?: number;
}

export const HomeIcon = <FontAwesomeIcon icon={faHouse} />;
export const CouponIcon = <FontAwesomeIcon icon={faTicket} />;
export const ClaimIcon = <FontAwesomeIcon icon={faReceipt} />;
export const CatalogIcon = <FontAwesomeIcon icon={faPaw} />;
export const DocumentIcon = <FontAwesomeIcon icon={faFolderOpen} />;
export const PanelIcon = <FontAwesomeIcon icon={faGear} />;

export const useMenu = (): [MenuData] => {
  const [menuData, setMenuData]: [MenuData, any] = useState({
    activeKeys: [],
    mainMenu: [],
    subMenu: [],
    userMenu: [],
    drawerMenu: [],
    mobileBarMenu: [],
  });

  const [user, setUser]: any = useState();
  const location = useLocation();
  const [query] = useSearchParams();
  const navigate = useNavigate();
  const intl = useIntl();
  const [changeRequestCount, setChangeRequestCount]: any = useState(0);

  useEffect(() => {
    authService.getUser().then(setUser);
  }, []);

  useEffect(() => {
    if (user && authService.hasRoles(user, [UserRole.INVENTORY_SUPERVISOR])) {
      petService.countChangeRequests().then(data => setChangeRequestCount(data?.count || 0));
      const subscription = socketService.getChangeRequests().subscribe(setChangeRequestCount);
      return () => subscription.unsubscribe();
    }
  }, [user]);

  useEffect(() => {
    if (!user) {
      return;
    }

    const userMenu: MenuProps['items'] = [
      { label: <Link to="/profile"><FormattedMessage id="menu.profile" /></Link>, key: 'profile' },
      { label: <Link to="/terms.pdf" target='_blank'><FormattedMessage id="termsConditions" /></Link>, key: 'terms' },
      { type: 'divider' },
      { label: <Link to="/logout"><FormattedMessage id="auth.logout" /></Link>, key: 'logout' },
    ];

    const inventorySubItems: SubMenuItem[] = [
      {
        label: <Link to="/inventory?type=CAT">{intl.formatMessage({ id: "menu.cats" })}</Link>,
        key: "cats",
        isActive: location.pathname === '/inventory' && query.get('type') === 'CAT' && !query.has('all'),
      },
      {
        label: <Link to="/inventory?type=DOG">{intl.formatMessage({ id: "menu.dogs" })}</Link>,
        key: "dogs",
        isActive: location.pathname === '/inventory' && query.get('type') === 'DOG' && !query.has('all'),
      },
      ...authService.hasRoles(user, [UserRole.INVENTORY_HORSES]) ? [{
        label: <Link to="/inventory?type=HRS">{intl.formatMessage({ id: "menu.horses" })}</Link>,
        key: "horses",
        isActive: location.pathname === '/inventory' && query.get('type') === 'HRS' && !query.has('all'),
      }] : [],
      {
        label: <Link to="/inventory?type=OTH">{intl.formatMessage({ id: "menu.other" })}</Link>,
        key: "other",
        isActive: location.pathname === '/inventory' && query.get('type') === 'OTH' && !query.has('all'),
      },
      ...authService.hasRoles(user, [UserRole.INVENTORY_VIEW_ALL]) ? [{
        label: <Link to="/inventory?all=true">{intl.formatMessage({ id: "menu.all" })}</Link>,
        key: "all",
        isActive: location.pathname === '/inventory' && query.has('all'),
      }] : [],
      ...authService.hasRoles(user, [UserRole.INVENTORY_SUPERVISOR]) ? [{
        label: <Link to="/inventory/change-request">{intl.formatMessage({ id: "menu.changeRequest" })}</Link>,
        key: "change-request",
        isActive: location.pathname === '/inventory/change-request',
        count: changeRequestCount,
      }] : [],
      ...authService.hasRoles(user, [UserRole.INVENTORY_VIEW_REPORTS]) ? [{
        label: <Link to="/report?source=inventory">{intl.formatMessage({ id: "menu.report" })}</Link>,
        key: "inventory.report",
        isActive: location.pathname.startsWith('/report') && query.get('source') === 'inventory',
      }] : [],
    ];

    const couponsSubItems: SubMenuItem[] = authService.hasRoles(user, [UserRole.COUPON_FUND, UserRole.COUPON_VIEW_ALL, UserRole.COUPON_FUND_VIEW_ALL, UserRole.COUPON_VIEW_REPORTS]) ? [
      {
        label: <Link to="/coupon">{intl.formatMessage({ id: "menu.coupons.my" })}</Link>,
        key: "coupons.coupons",
        isActive: location.pathname.startsWith('/coupon') && !query.has('all'),
      },
      ...authService.hasRoles(user, [UserRole.COUPON_VIEW_ALL]) ? [{
        label: <Link to="/coupon?all=true">{intl.formatMessage({ id: "menu.coupons.all" })}</Link>,
        key: "coupons.all",
        isActive: location.pathname.startsWith('/coupon') && query.has('all'),
      }] : [],
      ...authService.hasRoles(user, [UserRole.COUPON_FUND]) ? [{
        label: <Link to="/fund">{intl.formatMessage({ id: "menu.funds.my" })}</Link>,
        key: "coupons.funds",
        isActive: location.pathname.startsWith('/fund') && !query.has('all'),
      }] : [],
      ...authService.hasRoles(user, [UserRole.COUPON_FUND_VIEW_ALL]) ? [{
        label: <Link to="/fund?all=true">{intl.formatMessage({ id: "menu.funds.all" })}</Link>,
        key: "coupons.funds.all",
        isActive: location.pathname.startsWith('/fund') && query.has('all'),
      }] : [],
      ...authService.hasRoles(user, [UserRole.COUPON_VIEW_REPORTS]) ? [{
        label: <Link to="/report?source=coupon">{intl.formatMessage({ id: "menu.report" })}</Link>,
        key: "coupons.report",
        isActive: location.pathname.startsWith('/report') && query.get('source') === 'coupon',
      }] : [],
    ] : [];

    const claimSubItems: SubMenuItem[] = authService.hasRoles(user, [UserRole.CLAIM_VIEW_ALL, UserRole.CLAIM_VIEW_REPORTS]) ? [
      {
        label: <Link to="/claim">{intl.formatMessage({ id: "menu.claim.my" })}</Link>,
        key: "claim.my",
        isActive: location.pathname.startsWith('/claim') && !query.has('all'),
      },
      ...authService.hasRoles(user, [UserRole.CLAIM_VIEW_ALL]) ? [{
        label: <Link to="/claim?all=true">{intl.formatMessage({ id: "menu.all" })}</Link>,
        key: "claim.all",
        isActive: location.pathname.startsWith('/claim') && query.has('all'),
      }] : [],
      ...authService.hasRoles(user, [UserRole.CLAIM_VIEW_REPORTS]) ? [{
        label: <Link to="/report?source=claim">{intl.formatMessage({ id: "menu.report" })}</Link>,
        key: "claim.report",
        isActive: location.pathname.startsWith('/claim') && query.get('source') === 'claim',
      }] : [],
    ] : [];

    const documentSubItems: SubMenuItem[] = authService.hasRoles(user, [UserRole.DOCUMENT_VIEW_ALL, UserRole.DOCUMENT_VIEW_REPORTS]) ? [
      {
        label: <Link to="/document">{intl.formatMessage({ id: "menu.document.my" })}</Link>,
        key: "document.my",
        isActive: location.pathname.startsWith('/document') && !query.has('all'),
      },
      ...authService.hasRoles(user, [UserRole.DOCUMENT_VIEW_ALL]) ? [{
        label: <Link to="/document?all=true">{intl.formatMessage({ id: "menu.all" })}</Link>,
        key: "document.all",
        isActive: location.pathname.startsWith('/document') && query.has('all'),
      }] : [],
      ...authService.hasRoles(user, [UserRole.DOCUMENT_VIEW_REPORTS]) ? [{
        label: <Link to="/report?source=document">{intl.formatMessage({ id: "menu.report" })}</Link>,
        key: "document.report",
        isActive: location.pathname.startsWith('/report') && query.get('source') === 'document',
      }] : [],
    ] : [];

    const cpSubItems: SubMenuItem[] = [
      ...authService.hasRoles(user, [UserRole.SUPERUSER]) ? [{
        label: <Link to="/user">{intl.formatMessage({ id: "menu.users" })}</Link>,
        key: "users",
        isActive: location.pathname.startsWith('/user'),
      }, {
        label: <Link to="/group">{intl.formatMessage({ id: "menu.groups" })}</Link>,
        key: "groups",
        isActive: location.pathname.startsWith('/group'),
      }] : [],
      ...authService.hasRoles(user, [UserRole.PAGE_SUPERVISOR]) ? [{
        label: <Link to="/page">{intl.formatMessage({ id: "menu.pages" })}</Link>,
        key: "pages",
        isActive: location.pathname.startsWith('/page'),
      }] : [],
      ...authService.hasRoles(user, [UserRole.CONSENT_SUPERVISOR]) ? [{
        label: <Link to="/consent">{intl.formatMessage({ id: "menu.consents" })}</Link>,
        key: "consents",
        isActive: location.pathname.startsWith('/consent'),
      }] : [],
      ...authService.hasRoles(user, [UserRole.JOURNAL_ENCHANTED]) ? [{
        label: <Link to="/journal">{intl.formatMessage({ id: "menu.journal" })}</Link>,
        key: "journal",
        isActive: location.pathname === '/journal',
      }] : [],
      ...authService.hasRoles(user, [UserRole.PAYMENT_VIEW_REPORTS]) ? [{
        label: <Link to="/report?source=payment">{intl.formatMessage({ id: "menu.payment" })}</Link>,
        key: "payment.report",
        isActive: location.pathname.startsWith('/report') && query.get('source') === 'payment',
      }] : [],
    ];

    const mainMenu: MainMenuItem[] = [
      ...authService.hasRoles(user, [UserRole.USER]) ? [{
        label: intl.formatMessage({ id: "menu.home" }),
        key: "home",
        path: "/",
        icon: HomeIcon,
        isActive: location.pathname === '/',
      }] : [],
      ...authService.hasRoles(user, [UserRole.COUPONS]) ? [{
        label: intl.formatMessage({ id: "menu.coupons" }),
        key: "coupons",
        path: "/coupon",
        icon: CouponIcon,
        isActive: location.pathname.startsWith('/coupon') || couponsSubItems.find((item: SubMenuItem) => item.isActive) !== undefined,
        items: couponsSubItems,
      }] : [],
      ...authService.hasRoles(user, [UserRole.CLAIM]) ? [{
        label: intl.formatMessage({ id: "menu.claim" }),
        key: "claim",
        path: "/claim",
        icon: ClaimIcon,
        isActive: location.pathname.startsWith('/claim') || claimSubItems.find((item: SubMenuItem) => item.isActive) !== undefined,
        items: claimSubItems,
      }] : [],
      ...authService.hasRoles(user, [UserRole.INVENTORY]) ? [{
        label: intl.formatMessage({ id: "menu.inventory" }),
        key: "inventory",
        path: "/inventory?type=CAT",
        icon: CatalogIcon,
        isActive: location.pathname.startsWith('/inventory') || inventorySubItems.find((item: SubMenuItem) => item.isActive) !== undefined,
        items: inventorySubItems,
        count: !location.pathname.startsWith('/inventory') ? changeRequestCount : 0,
      }] : [],
      ...authService.hasRoles(user, [UserRole.DOCUMENT]) ? [{
        label: intl.formatMessage({ id: "menu.document" }),
        key: "document",
        path: "/document",
        icon: DocumentIcon,
        isActive: location.pathname.startsWith('/document') || documentSubItems.find((item: SubMenuItem) => item.isActive) !== undefined,
        items: documentSubItems,
      }] : [],
      ...authService.hasRoles(user, [UserRole.PAYMENT_VIEW_REPORTS, UserRole.JOURNAL_ENCHANTED, UserRole.SUPERUSER]) ? [{
        label: intl.formatMessage({ id: "menu.controlPanel" }),
        key: "controlPanel",
        path: authService.hasRoles(user, [UserRole.SUPERUSER]) ? "/user" : authService.hasRoles(user, [UserRole.JOURNAL_ENCHANTED]) ? "/journal" : "/report?source=payment",
        icon: PanelIcon,
        isActive: cpSubItems.find((item: SubMenuItem) => item.isActive) !== undefined,
        items: cpSubItems,
      }] : [],
    ];

    const drawerMenu: MenuProps['items'] = [
      {
        label: intl.formatMessage({ id: "menu.home" }),
        key: "home",
        icon: <FontAwesomeIcon icon={faHouse} />,
        onClick: () => navigate('/'),
      },
      ...authService.hasRoles(user, [UserRole.COUPONS]) ? [{
        label: intl.formatMessage({ id: "menu.coupons" }),
        key: "coupons",
        icon: CouponIcon,
        onClick: () => navigate('/coupon'),
      }] : [],
      ...authService.hasRoles(user, [UserRole.CLAIM]) ? [{
        label: intl.formatMessage({ id: "menu.claim" }),
        key: "claim",
        icon: ClaimIcon,
        onClick: () => navigate('/claim'),
      }] : [],
      ...authService.hasRoles(user, [UserRole.INVENTORY]) ? [{
        label: intl.formatMessage({ id: "menu.inventory" }),
        key: "inventory",
        icon: <FontAwesomeIcon icon={faPaw} />,
        onClick: () => navigate('/inventory?type=CAT'),
      }] : [],
      ...authService.hasRoles(user, [UserRole.DOCUMENT]) ? [{
        label: intl.formatMessage({ id: "menu.document" }),
        key: "document",
        icon: <FontAwesomeIcon icon={faFolderOpen} />,
        onClick: () => navigate('/document'),
      }] : [],
      { type: 'divider' },
      ...userMenu,
    ];

    const mobileBarMenu: MainMenuItem[] = [
      ...authService.hasRoles(user, [UserRole.COUPONS]) ? [{
        label: intl.formatMessage({ id: "menu.coupons" }),
        key: "coupons",
        path: "/coupon",
        icon: CouponIcon,
        isActive: location.pathname.startsWith('/coupon') || couponsSubItems.find((item: SubMenuItem) => item.isActive) !== undefined,
        items: couponsSubItems,
      }] : [],
      ...authService.hasRoles(user, [UserRole.CLAIM]) ? [{
        label: intl.formatMessage({ id: "menu.claim" }),
        key: "claim",
        path: "/claim",
        icon: ClaimIcon,
        isActive: location.pathname.startsWith('/claim') || claimSubItems.find((item: SubMenuItem) => item.isActive) !== undefined,
        items: claimSubItems,
      }] : [],
      ...authService.hasRoles(user, [UserRole.INVENTORY]) ? [{
        label: intl.formatMessage({ id: "menu.inventory" }),
        key: "inventory",
        path: "/inventory?type=CAT",
        icon: <FontAwesomeIcon icon={faPaw} />,
        isActive: location.pathname.startsWith('/inventory') || inventorySubItems.find((item: SubMenuItem) => item.isActive) !== undefined,
        items: inventorySubItems,
        count: !location.pathname.startsWith('/inventory') ? changeRequestCount : 0,
      }] : [],
      ...authService.hasRoles(user, [UserRole.DOCUMENT]) ? [{
        label: intl.formatMessage({ id: "menu.document" }),
        key: "document",
        path: "/document",
        icon: <FontAwesomeIcon icon={faFolderOpen} />,
        isActive: location.pathname.startsWith('/document') || documentSubItems.find((item: SubMenuItem) => item.isActive) !== undefined,
        items: documentSubItems,
      }] : [],
      ...authService.hasRoles(user, [UserRole.PAYMENT_VIEW_REPORTS, UserRole.JOURNAL_ENCHANTED, UserRole.SUPERUSER]) ? [{
        label: intl.formatMessage({ id: "menu.controlPanel" }),
        key: "controlPanel",
        path: authService.hasRoles(user, [UserRole.SUPERUSER]) ? "/user" : authService.hasRoles(user, [UserRole.JOURNAL_ENCHANTED]) ? "/journal" : "/report?source=payment",
        icon: <FontAwesomeIcon icon={faGear} />,
        isActive: cpSubItems.find((item: SubMenuItem) => item.isActive) !== undefined,
        items: cpSubItems,
      }] : [],
      {
        label: intl.formatMessage({ id: "menu.profile.short" }),
        key: "profile",
        path: "/profile",
        icon: <FontAwesomeIcon icon={faUser} />,
        isActive: location.pathname === '/profile',
      },
    ];

    const activeMenu: MainMenuItem | undefined = mainMenu.find((item: MainMenuItem) => item.isActive);
    const activeKeys: string[] = activeMenu ? [activeMenu.key] : [];


    if (activeMenu && activeMenu.items) {
      activeMenu.items.forEach((sub: SubMenuItem) => {
        if (sub.isActive) {
          activeKeys.push(sub.key);
        }
      })
    }

    setMenuData({ activeKeys, mainMenu, subMenu: activeMenu && activeMenu.items, userMenu, drawerMenu, mobileBarMenu });
  }, [changeRequestCount, intl, location, navigate, query, user]);

  return [menuData];
};

export default useMenu;