import { UserRole } from '../enums/UserRole';
import sessionService from './session.service';
import httpService, { httpHelper } from './http.service';
import { ENDPOINT } from './urls.service';

export interface User {
  username: string;
  roles: UserRole[];
}

export interface UserToken {
  value: string;
  ttl: number;
}

class AuthService {
  private sessionKey = '452c7b018798-9901';
  private tokenKey = '0242ac120002-b9d1';

  async getToken(invalidate = false) {
    const _token: UserToken = sessionService.get(this.tokenKey);

    if (!_token?.value || invalidate || (new Date().getTime()) >= Number(_token?.ttl ?? 0)) {
      try {
        const { data } = await httpHelper.getPoolWrapper(`${ENDPOINT}/user/v1/token`, { withCredentials: true });
        const value = `${data?.token_type} ${data?.access_token}`;
        sessionService.set(this.tokenKey, { value, ttl: (data?.timestamp || new Date().getTime()) + data?.expires_in } as UserToken);
        return value;
      } catch (e) {
        sessionService.remove(this.tokenKey);
        throw e;
      }
    }

    return _token.value;
  }

  async getUser(invalidate = false) {
    return httpHelper.cacheWrapper(
      this.sessionKey,
      async () => {
        const { data } = await httpHelper.getPoolWrapper(`${ENDPOINT}/user/v1/me`, { withCredentials: true });
        return data;
      },
      360000,
      false,
      false,
    );
  }

  invalidate() {
    sessionService.remove(this.sessionKey, true);
    sessionService.remove(this.tokenKey, true);
  }

  authorize(username: string, password: string, token: string, rememberMe?: boolean) {
    return httpService.post(`${ENDPOINT}/user/v1/authorize`, { username, password, token, rememberMe }, {
      withCredentials: true
    });
  }

  requestOTP(email: string, token: string) {
    return httpService.post(`${ENDPOINT}/user/v1/request-otp`, { email, token }, {
      withCredentials: true
    });
  }

  resetPassword(email: string, password: string, code: string, token: string) {
    return httpService.post(`${ENDPOINT}/user/v1/reset-password`, { email, password, code, token }, {
      withCredentials: true
    });
  }

  sendOTP() {
    return httpHelper.post(`${ENDPOINT}/user/v1/send-otp`, {});
  }

  changePassword(payload: any) {
    return httpHelper.post(`${ENDPOINT}/user/v1/change-password`, payload);
  }

  signup(username: string, password: string, email: string, token: string) {
    return httpService.post(`${ENDPOINT}/user/v1/sugnup`, { username, password, email, token }, {
      withCredentials: true
    });
  }

  async evaluateUsername(username: string, token: string) {
    const { data } = await httpService.post(`${ENDPOINT}/user/v1/evaluateUsername`, { username, token }, {
      withCredentials: true
    });
    return data;
  }

  async evaluateEmail(email: string, token: string) {
    const { data } = await httpService.post(`${ENDPOINT}/user/v1/evaluateEmail`, { email, token }, {
      withCredentials: true
    });
    return data;
  }

  async impersonalizate(username: string) {
    await httpService.post(`${ENDPOINT}/user/v1/impersonalizate`, { username }, { withCredentials: true });
    sessionService.clear();
  }

  async impersonalizateOff() {
    await httpService.delete(`${ENDPOINT}/user/v1/impersonalizate`, { withCredentials: true });
    sessionService.clear();
  }

  async update(payload: any) {
    this.invalidate();
    return httpHelper.post(`${ENDPOINT}/user/v1/update`, payload);
  }

  async logout() {
    sessionService.clear();
    await httpService.get(`${ENDPOINT}/user/v1/logout`, { withCredentials: true });
  }

  async logoutAll() {
    sessionService.clear();
    await httpService.get(`${ENDPOINT}/user/v1/logout-all`, { withCredentials: true });
  }

  hasRoles(user: Partial<User>, roles: UserRole[]): boolean {
    if (user.roles?.includes(UserRole.SUPERUSER)) {
      return true;
    }

    return roles.filter((role) => user?.roles?.includes(role)).length > 0;
  }
}

export const authService = new AuthService();
export default authService;