import { Socket, io } from 'socket.io-client';
import { Observable, Subject } from "rxjs";
import authService from "./auth.service";
import { WEBSOCKET, WEBSOCKET_URL } from './urls.service';

export class SocketService {
  private socket;
  private _intervalReconnect;

  private connectionStatusSubject = new Subject();
  private rolesSubject = new Subject();
  private consentSubject = new Subject();
  private changesSubject = new Subject();
  private changeRequestsSubject = new Subject();

  async connect(): Promise<void> {
    if (!this.socket) {
      const token = await authService.getToken();

      this.socket = io(WEBSOCKET, {
        path: WEBSOCKET_URL,
        extraHeaders: {
          Authorization: token
        },
        transportOptions: {
          polling: {
            extraHeaders: {
              Authorization: token
            },
          },
        },
      }) as Socket;

      if (!this._intervalReconnect) {
        this._intervalReconnect = setInterval(async () => {
          await this.disconnect();
          await this.connect();
        }, 5 * 60 * 1000); // 5min;
      }

      this.socket.on('connect', () => this.connectionStatusSubject.next(true));
      this.socket.on('disconnect', () => this.connectionStatusSubject.next(false));
      this.socket.on('roles', (data: any) => this.rolesSubject.next(data));
      this.socket.on('consent', (data: any) => this.consentSubject.next(data));
      this.socket.on('changes', (data: any) => this.changesSubject.next(data));
      this.socket.on('change-request', (data: any) => this.changeRequestsSubject.next(data));
    } else {
      this.connectionStatusSubject.next(this.socket.connected)
    }
  }

  async disconnect(): Promise<void> {
    this.socket.off('connect');
    this.socket.off('disconnect');
    this.socket.off('roles');
    this.socket.off('consent');
    this.socket.off('changes');
    this.socket.off('change-request');
    this.socket.disconnect();
    this.socket = undefined;
  }

  getConnectionStatus(): Observable<any> {
    return this.connectionStatusSubject.asObservable();
  }

  getRolesChanged(): Observable<any> {
    return this.rolesSubject.asObservable();
  }

  getConsentChanged(): Observable<any> {
    return this.consentSubject.asObservable();
  }

  getChanges(): Observable<any> {
    return this.changesSubject.asObservable();
  }

  getChangeRequests(): Observable<any> {
    return this.changeRequestsSubject.asObservable();
  }
}

export const socketService = new SocketService();
export default socketService;
