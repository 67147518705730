import React from 'react';
import { Typography } from 'antd';

import '@litvinok/petapps-ckeditor5-build/assets/content-styles.css';

export interface RichTextProps {
  text: string;
  style?: React.CSSProperties;
}

function RichText({ text, style }: RichTextProps) {
  return <Typography className="ck-content" style={style} dangerouslySetInnerHTML={{ __html: text }} />;
}

export default RichText;