
import couponService from './coupon.service';
import { DistributedDataService, httpHelper } from './http.service';
import { ENDPOINT } from './urls.service';

class FundService extends DistributedDataService {
  async list(params: URLSearchParams) {
    return httpHelper.get(`${ENDPOINT}/fund-secured/v1/fund`, params);
  }

  async get(id: string) {
    return httpHelper.get(`${ENDPOINT}/fund-secured/v1/fund/${id}`);
  }

  async create(payload: any) {
    return httpHelper.put(`${ENDPOINT}/fund-secured/v1/fund`, payload);
  }

  async update(id: string, payload: any) {
    couponService.invalidateScore();
    return httpHelper.post(`${ENDPOINT}/fund-secured/v1/fund/${id}`, payload);
  }

  async delete(id: string) {
    couponService.invalidateScore();
    return httpHelper.delete(`${ENDPOINT}/fund-secured/v1/fund/${id}`);
  }

  async report(params: URLSearchParams): Promise<any> {
    return [];
  }
}

export const fundService = new FundService();
export default fundService;