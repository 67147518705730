import { ReactNode } from 'react';
import { App, ConfigProvider, theme } from 'antd';
import { IntlProvider } from 'react-intl';
import dateService from '../../services/date.service';
import Loader from './Loader';

import intlData from "../../assets/locale/ru_RU.json";
import antdData from "antd/locale/ru_RU";
import dayjsData from "dayjs/locale/ru";

// https://github.com/localizely/react-intl-example/blob/main/src/App.js
function AppProvider({ children }: { children?: ReactNode }) {
  const algorithm = theme.defaultAlgorithm;
  dateService.locale(dayjsData);

  return (
    intlData && antdData ? <IntlProvider locale="ru" messages={intlData}>
      <ConfigProvider locale={antdData} theme={{ algorithm, token: { borderRadius: 3 }, cssVar: true, hashed: false }}>
        <App>
          {children}
        </App>
      </ConfigProvider>
    </IntlProvider> : <Loader />
  );
};

export default AppProvider;
