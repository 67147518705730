import ReactGA from 'react-ga4';

const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

export class AnalyticService {
  initialize() {
    ReactGA.initialize(isProduction ? 'G-XCHMHN5E1L' : 'G-MW053Y64B9');
  }

  trackUser(userId: string) {
    ReactGA.set({ userId });
  }

  trackFormError() {
    ReactGA.event({ category: 'Form', action: 'Error' });
  }

  trackFormValidation() {
    ReactGA.event({ category: 'Form', action: 'Validation' });
  }

  trackSignConsent() {
    ReactGA.event({ category: 'Consent', action: 'Sign' });
  }

  modalview(name) {
    ReactGA.send({
      hitType: "modalview",
      page: window.location.pathname + window.location.search,
      title: name
    });
  }

  pageview() {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: document?.title
    });
  }
}

export const analyticService = new AnalyticService();