
import { DistributedDataService, httpHelper } from './http.service';
import { ENDPOINT } from './urls.service';

class CouponService extends DistributedDataService {
  private scoreKey = 'cb531105';

  async list(params: URLSearchParams) {
    return httpHelper.get(`${ENDPOINT}/coupon-secured/v1/coupon`, params);
  }

  async get(id: string) {
    return httpHelper.get(`${ENDPOINT}/coupon-secured/v1/coupon/${id}`);
  }

  async create(payload: any) {
    this.invalidateScore();
    return httpHelper.put(`${ENDPOINT}/coupon-secured/v1/coupon`, payload);
  }

  async update(id: string, payload: any) {
    this.invalidateScore();
    return httpHelper.post(`${ENDPOINT}/coupon-secured/v1/coupon/${id}`, payload);
  }

  async delete(id: string,) {
    this.invalidateScore();
    return httpHelper.delete(`${ENDPOINT}/coupon-secured/v1/coupon/${id}`);
  }

  async report(params: URLSearchParams) {
    return httpHelper.get(`${ENDPOINT}/coupon-secured/v1/report`, params);
  }

  async search(text: string) {
    return httpHelper.cacheWrapper(`ba3ceadc_${text}`, () => httpHelper.get(`${ENDPOINT}/coupon-secured/v1/search`, new URLSearchParams([['query', text]])));
  }

  async updateScore() {
    this.invalidateScore();
    return httpHelper.post(`${ENDPOINT}/coupon-secured/v1/score`, {});
  }

  async invalidateScore() {
    httpHelper.invalidateCache(this.scoreKey);
  }

  async score() {
    return httpHelper.cacheWrapper(this.scoreKey, () => httpHelper.get(`${ENDPOINT}/coupon-secured/v1/score`));
  }

  async checkScore(username: string) {
    return httpHelper.cacheWrapper(`c77fe2f9_${username}`, () => httpHelper.get(`${ENDPOINT}/coupon-secured/v1/score`, new URLSearchParams([['username', username]])), 60000);
  }

  async team() {
    return httpHelper.cacheWrapper('93aa5592', () => httpHelper.get(`${ENDPOINT}/coupon-secured/v1/team`));
  }

  async getTypes(current: any) {
    return httpHelper.cacheWrapper(`d055bcc3_${current || ''}`, () => httpHelper.get(`${ENDPOINT}/coupon-secured/v1/type`, new URLSearchParams([['current', current]])));
  }

  async getMyVets() {
    return httpHelper.cacheWrapper('8c2006b0', () => httpHelper.get(`${ENDPOINT}/coupon-secured/v1/my-vet`), 60000);
  }

  async getVets() {
    return httpHelper.cacheWrapper('d8f63f0c', () => httpHelper.get(`${ENDPOINT}/coupon-secured/v1/vet`));
  }

  async getGoals(type?: string) {
    return httpHelper.cacheWrapper(`21291b54_${type || ''}`, () => httpHelper.get(`${ENDPOINT}/coupon-secured/v1/goal`, new URLSearchParams([['type', type || '']])));
  }
}

export const couponService = new CouponService();
export default couponService;