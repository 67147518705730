import { httpHelper } from "./http.service";
import { ENDPOINT } from "./urls.service";

class LogService {
  log(data) {
    this.track(data);
    console.log(data);
  }

  error(data) {
    this.track(data);
    console.error(data);
  }

  private track(data) {
    httpHelper.post(`${ENDPOINT}/log`, data);
  }
}

export const logService = new LogService();
export default logService;